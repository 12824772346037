import React, { useContext, useEffect } from 'react';
import routes from "./routes";
import { useRoutes } from "react-router-dom";
import { ThemeProvider, CssBaseline, StyledEngineProvider, Box } from '@mui/material';
import { ColorModeContext, useMode } from './theme'; // Adjust the import path as necessary
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import { useSelector } from 'react-redux';


// Gradient background
const gradientBackground = 'linear-gradient(to bottom right, #0f0c29, #008080)';

// Styled Paper component
const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  textAlign: 'center',
  color: theme.palette.text.secondary,
  marginLeft: '5%',
  marginRight: '5%',
  marginBottom: '5%',
  maxHeight: '90vh',
  overflowY: 'auto',

  [theme.breakpoints.down('sm')]: {
    width: '100%',
    marginLeft: 0,
    marginRight: 0,
    marginBottom: '10px',
    height: 'auto',
  },
}));

function App() {
  const [theme, colorMode] = useMode();
  const colorModeContext = useContext(ColorModeContext);
  const { isAuthenticated, status, error } = useSelector((state) => state.auth);

  useEffect(() => {
      const currentUrl = window.location.href;
      const ipPattern = /^(http:\/\/|https:\/\/)?(\d{1,3}\.){3}\d{1,3}/;

      if (ipPattern.test(currentUrl)) {
          window.location.href = 'https://enc-worksphere.com/login';
      }
  }, []);

  // Check if the user already logged in or not
  const isLoggedIn = ()=>{
    return isAuthenticated
  };
  const content = useRoutes(routes(isLoggedIn()));

  return (
    <ColorModeContext.Provider value={colorMode}>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Box
            sx={{
              height: '100vh',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              padding: '2px',
              background: gradientBackground,
            }}
          >
            {content}
          </Box>
        </ThemeProvider>
      </StyledEngineProvider>
    </ColorModeContext.Provider>
  );
}

export default App;