import axios from 'axios';
import { store } from './store';
import { refreshAccessToken, logout } from './reducers/authSlice';
import { apiUrl } from './constants';

const api = axios.create({
  baseURL: apiUrl.HOST,
  headers: {
    'Content-Type': 'application/json',
  },
  timeout: 10000,
});

api.interceptors.request.use(
  (config) => {
    const token = store.getState().auth.accessToken;
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

api.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;

      try {
        const result = await store.dispatch(refreshAccessToken());
        if (result.payload.accessToken) {
          originalRequest.headers['Authorization'] = `Bearer ${result.payload.accessToken}`;
          return api(originalRequest);
        }
      } catch (err) {
        store.dispatch(logout());
      }
    }
    return Promise.reject(error);
  }
);

export default api;
