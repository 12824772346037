import { useState } from 'react'
import { FaSearch } from "react-icons/fa";
import { apiUrl } from '../constants'
import api from '../axiosInstance';
import "./SearchBar.css";
import "./SearchResultList.css";
import "./SearchResult.css"
import { fontSize } from '@mui/system';
const SearchBar = ({subPath, setMemberListJSON, setProjectManager, tabValue}) => {
    const [text, setText] = useState("");
    const [searchResults, setSearchResults] = useState([]);

    const fetchMember = async (member) => {
      try {
        const response = await api.post(apiUrl.MEMBER_SEARCH_URL, {'textSearch': text});
        setSearchResults(response.data.name)
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    }
    const handleTextChange = (value) => {
        setText(value);
        fetchMember(value);
      };

    const handleAddMember = async (value) => {
      if (tabValue === '1') {
        setManager(value)
      } else {
        addMember(value)
      }
      
    }

    const memberList = async () => {
        try {
          const response = await api.post(apiUrl.MEMBERS_URL, {'checkOn': subPath});
          setMemberListJSON(response.data.members) 
        } catch (error) {
          console.error('Error fetching data:', error);
        }
    }

    const addMember = async (value) => {
      const [firstName, lastName] = value.split(' ')
      try {
        const response = await api.post(apiUrl.ADD_MEMBER_URL, {
          'checkOn': subPath,
          'firstName': firstName,
          'lastName':lastName, 
          'role': tabValue === 1? 'Project Manager': 'Member'
        });
        setSearchResults(response.data.name)
        memberList()
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    }

    const setManager = (value) => {
      setProjectManager(value)
    }

  return (
    <div>
      <div className="input-wrapper">
        <FaSearch id="search-icon" />
        <input
          placeholder="Type to search..."
          value={text}
          onChange={(e) => handleTextChange(e.target.value)}
        />
      </div>
      <div className="results-list">
      {searchResults !== undefined ?searchResults.map((result, id) => {
        return (
          <div
            className="search-result"
            onClick={(e) => handleAddMember(result)}
          >
          {result}
    </div>
        )
      }): ''}
    </div>
    </div>
    
  )
}

export default SearchBar
