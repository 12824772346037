import { Box, Button, Checkbox, Tab, TextField, Grid, Paper, styled, Typography, InputLabel, InputAdornment, Portal } from '@mui/material'
import { useState, useEffect} from 'react'
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import Tooltip from '@mui/material/Tooltip';
import Stack from '@mui/material/Stack';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { tokens } from "../../theme";
import '../../index.css'
import Swal from 'sweetalert2';
import { apiUrl } from '../../constants'
import axios from 'axios'
import SearchBar from '../../components/SearchBar'
import withReactContent from 'sweetalert2-react-content';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import { Link } from 'react-router-dom'
import CircularProgress from '@mui/material/CircularProgress';
import SearchIcon from '@mui/icons-material/Search';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import FilteringTable from '../../components/FilteringTable';
import api from '../../axiosInstance';
import { useSelector } from 'react-redux';
import { setTeam } from '../../reducers/authSlice';



const MySwal = withReactContent(Swal);

const ProSpan = styled('span')({
    display: 'inline-block',
    height: '1em',
    width: '1em',
    verticalAlign: 'middle',
    marginLeft: '0.3em',
    marginBottom: '0.08em',
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    backgroundImage: 'url(https://mui.com/static/x/pro.svg)',
  });

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: 'transparent',
    ...theme.typography.body2,
    textAlign: 'center',
    color: theme.palette.text.secondary,
    marginLeft: '2%',
    marginRight: '2%',
    marginBottom: '2%',
    maxHeight: '90vh', 
    overflowY: 'auto',

    [theme.breakpoints.down('sm')]: {
        width: '100%',
        marginLeft: 0,
        marginRight: 0,
        marginBottom: '10px',
        height: 'auto',
    },
}));

const CustomTableRow = styled(TableRow)(({ theme }) => ({
  '&:hover': {
    backgroundColor: 'rgba(255, 255, 255, 0.2)', // Change background color when hovered
  },
}));


const FormSummary = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const year = new Date().getFullYear();
  const [isFirstTimeLoading, setIsFirstTimeLoading] = useState(true);
  const [subTeam, setSubTeam] = useState('')
  const { team, role } = useSelector((state) => state.auth);
  const [subTeamSelectionArray, setSubTeamSelectionArray] = useState([])
  const [projects, setProjects] = useState([])
  const [filteredProjects, setFilteredProjects] = useState([])
  const [filteredProjectName, setFilteredProjectName] = useState([])
  const [open, setOpen] = useState(false);
  const [currentItem, setCurrentItem] = useState(null);
  
  

  const teamArrayList = [
    { id: 1, label: 'พัฒนาประสิทธิภาพและนวัตกรรม' },
    { id: 2, label: 'กลยุทธ์และบริหารงานวิศวกรรม' },
    { id: 3, label: 'วิศวกรรมเพื่อความยั่งยืน' },
    { id: 4, label: 'พื้นฐานและงานระบบ' },
    { id: 6, label: 'อาหารสำเร็จรูป' },
    { id: 7, label: 'แปรรูปเนื้อสัตว์' },
    { id: 8, label: 'วิศวกรรมสัตว์น้ำ' },
    { id: 9, label: 'สัตว์บก' },
    { id: 10, label: 'เกษตร' },
    { id: 10, label: 'SHE&EN' }
  ]
  
  // get sub-team, first loading
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await api.post(apiUrl.MASTER_SUBTEAM_URL, {'team': team});
        setSubTeamSelectionArray(response.data.subteams)
        setIsFirstTimeLoading(false)
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchData();

  }, []);

  // get projects, first loading
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await api.post(apiUrl.TEAM_PROJECTS_SUMMARY_URL, {'team': team, 'year': year});
        setProjects(response.data.projects)
        setFilteredProjects(response.data.projects)
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchData();
  }, []);
 
  const handleSubTeam = (event) => {
    setSubTeam(event.target.value);
    setFilteredProjects(projects.filter(item => item.subteam === event.target.value))
  };

  const handleTeam = (event) => {
    setTeam(event.target.value);
    setFilteredProjects(projects.filter(item => item.team === event.target.value))
  };

  const handleDeleteProject = async (value) => {
      const fetchData = async () => {
        try {
          const response = await api.post(apiUrl.TEAM_PROJECTS_SUMMARY_URL, {'team': team, 'checkOn': year});
          setProjects(response.data.projects)
          setFilteredProjects(response.data.projects)
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };

      const deleteData = async (checkOn, team) => {
        try {
          const response = await api.delete(`${apiUrl.DELETE_PROJECT_URL}`, {
            data: { team: team, checkOn: checkOn },
          })
          .then(()=>{
            fetchData();
          });
          
        } catch (error) {
          console.error('Error deleting data:', error);
        }
      };
      deleteData(value, team);
  }
  
  const handleLinkClick = (value) => {
    localStorage.setItem('rb_team_pms', value);
  }

  // const downloadCSV = () => {
  //   const headers = ['Year', 'Project Name', 'Subteam', 'Strategic Direction CPF', 'Strategic Direction COE',
  //     'Project Type', 'BU', 'Location', 'Project Manager', 'Members', 'Period', 'Status', 'Percent Progress',
  //     'Progress Update', 'Problem', 'Next Step', 'Week', 'Latest Update', 'Budget', 'Cost Saving Type',
  //     'Actual Saving (MB)', 'Target Saving (MB)'
  //   ];
  //   const rows = filteredProjects.map(project => [
  //     project.year,
  //     project.project_name,
  //     project.subteam,
  //     project.strategic_direction_cpf,
  //     project.strategic_direction_coe,
  //     project.project_type,
  //     project.bu,
  //     project.location,
  //     project.project_manager,
  //     project.members,
  //     `${project.start_date ? new Intl.DateTimeFormat('en-US', { month: 'short', year: 'numeric' }).format(new Date(project.start_date.substring(0, 10))) : 'N/A'} - ${project.finish_date ? new Intl.DateTimeFormat('en-US', { month: 'short', year: 'numeric' }).format(new Date(project.finish_date.substring(0, 10))) : 'N/A'}`,
  //     project.status,
  //     project.percent_progress,
  //     project.progress_update,
  //     project.problem,
  //     project.next_step,
  //     project.week,
  //     project.latest_created_at,
  //     project.budget,
  //     project.cost_saving_type,
  //     project.total_actual_saving_mb,
  //     project.total_target_saving_mb,
 
  //   ]);
  
  //   // Helper function to escape CSV fields
  //   const escapeCSVField = (field) => {
  //     if (typeof field === 'string') {
  //       if (field.startsWith('-')) {
  //         field = "'" + field;
  //       }
  //       return `"${field.replace(/"/g, '""')}"`;
  //     }
  //     return field;
  //   };
  
  //   let csvContent = headers.map(escapeCSVField).join(",") + "\n"
  //     + rows.map(row => row.map(escapeCSVField).join(",")).join("\n");
  //   const bom = "\uFEFF";
  //   const csvData = new Blob([bom + csvContent], { type: 'text/csv;charset=utf-8;' });
  //   const link = document.createElement("a");
  //   const url = URL.createObjectURL(csvData);
  //   link.setAttribute("href", url);
  //   link.setAttribute("download", "projects.csv");
  //   document.body.appendChild(link);
  //   link.click();
  //   document.body.removeChild(link);
  // };

  const downloadCSV = () => {
    // Derive headers dynamically from the first object in filteredProjects
    const headers = filteredProjects.length > 0 ? Object.keys(filteredProjects[0]) : [];
    
    // Generate rows dynamically based on the available keys in filteredProjects
    const rows = filteredProjects.map(project => 
      headers.map(header => project[header] ?? '') // Use nullish coalescing to handle undefined values
    );
  
    // Helper function to escape CSV fields
    const escapeCSVField = (field) => {
      if (typeof field === 'string') {
        if (field.startsWith('-')) {
          field = "'" + field;
        }
        return `"${field.replace(/"/g, '""')}"`;
      }
      return field;
    };
  
    // Build CSV content
    let csvContent = headers.map(escapeCSVField).join(",") + "\n"
      + rows.map(row => row.map(escapeCSVField).join(",")).join("\n");
    
    const bom = "\uFEFF"; // Add BOM for proper UTF-8 encoding
    const csvData = new Blob([bom + csvContent], { type: 'text/csv;charset=utf-8;' });
    
    // Create a download link and trigger download
    const link = document.createElement("a");
    const url = URL.createObjectURL(csvData);
    link.setAttribute("href", url);
    link.setAttribute("download", "projects.csv");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  
  const handleClickOpen = (item) => {
    setCurrentItem(item); // Set the item to be deleted
    setOpen(true); // Open the dialog
  };

  const handleClose = () => {
    setOpen(false); // Close the dialog without deleting
  };

  const handleConfirmDelete = () => {
    handleDeleteProject(currentItem); // Call the delete function with the current item
    setOpen(false); // Close the dialog after deletion
  };

  return (
    <Box  sx={{ padding: '0px', backgroundColor: 'transparent',  position: 'relative', top: '-20px', }}>
      <Item>
        <Box sx={{ width: '100%', backgroundColor: 'transparent',}}>
          <Typography 
            variant="h2"
            component="div"
            color='#FFD700'
            sx={{ 
                display: 'block',
                whiteSpace: 'nowrap',
                textAlign: 'center',
                overflow: 'hidden',  
                textOverflow: 'ellipsis', 
                width: '100%' }}
          > {localStorage.getItem('rb_team_pms')} {year} </Typography>
          <Box display="flex" alignItems="center">
            {/* {
              localStorage.getItem('rb_Admin_pms') === "false" ?
              <FormControl required fullWidth size='small'>
                <InputLabel>Select a Subteam</InputLabel>
                <Select
                  value={subTeam}
                  label="Select an Option"
                  placeholder={subTeam !== '' ? subTeam : 'Select an option'}
                  onChange={handleSubTeam}
                  sx={{
                    '& .MuiSelect-select': {
                      textAlign: 'left', // Align text to the left
                      color: '#333', // Text color
                    },
                  }}
                >
                  {subTeamSelectionArray !== undefined ? subTeamSelectionArray.map((option) => (
                    <MenuItem key={option} value={option}>{option}</MenuItem>
                  )) : ''}
                </Select>
              </FormControl>
              : 
              <FormControl required fullWidth size='small'>
                <InputLabel>Select a Subteam</InputLabel>
                <Select
                  value={team}
                  label="Select an Option"
                  placeholder={team !== '' ? subTeam : 'Select an option'}
                  onChange={handleTeam}
                  sx={{
                    '& .MuiSelect-select': {
                      textAlign: 'left', // Align text to the left
                      color: '#333', // Text color
                    },
                  }}
                >
                  {teamArrayList.length !== 0 ? teamArrayList.map((option) => (
                    <MenuItem key={option.label} value={option.label}>{option.label}</MenuItem>
                  )) : ''}
                </Select>
              </FormControl>

            } */}
            <FormControl required fullWidth size='small'>
                <InputLabel
                    sx={{
                        color: '#B2EBF2', // Cyan color for label
                        '&.Mui-focused': {
                          color: '#B2EBF2' // Ensure label remains cyan when focused
                        },
                        '&.MuiInputLabel-shrink': {
                          color: '#B2EBF2' // Ensure label remains cyan when it shrinks
                        },
                      }}
                > 
                    Selection an option 
                </InputLabel>
                <Select
                  value={subTeam}
                  label="Select an Option"
                  placeholder={subTeam !== '' ? subTeam : 'Select an option'}
                  onChange={handleSubTeam}
                  sx={{
                    '& .MuiSelect-select': {
                      textAlign: 'left', // Align text to the left
                      color: '#B2EBF2', // Text color
                    },
                    '& .MuiOutlinedInput-notchedOutline': {
                      borderColor: '#B2EBF2', // Border color
                    },
                    '&:hover .MuiOutlinedInput-notchedOutline': {
                      borderColor: '#B2EBF2', // Border color on hover
                    },
                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                      borderColor: '#B2EBF2', // Border color when focused
                    },
                    backgroundColor: 'transparent', // Set a lighter background color
                  }}
                  MenuProps={{
                    PaperProps: {
                      sx: {
                        backgroundColor: '#008080', // Teal background for the selection list
                        '& .MuiMenuItem-root': {
                          color: '#FFFFFF', // Text color for the menu items
                        },
                        '& .MuiMenuItem-root:hover': {
                          backgroundColor: '#005f5f', // Darker teal on hover
                        },
                      },
                    },
                  }}

                >
                  {subTeamSelectionArray !== undefined ? subTeamSelectionArray.map((option) => (
                    <MenuItem key={option} value={option}>{option}</MenuItem>
                  )) : ''}
                </Select>
              </FormControl>
            
            <IconButton
              component={Link} // Use Link component
              to="/form" // Specify the destination path
              color="primary"
              aria-label="add"
              sx={{
                backgroundColor: '#009688', // Cyan button color
                color: '#FFFFFF', // White icon color
                '&:hover': {
                  backgroundColor: '#00796B', // Darker cyan on hover
                },
              }}
            >
              <AddIcon />
            </IconButton>
          </Box>
          <br/>
          <Box display="flex" alignItems="center" width="100%">
            <Typography 
              variant="h4"
              component="div"
              ml="10px"
              sx={{ 
                display: 'block',
                whiteSpace: 'nowrap',
                textAlign: 'left',
                overflow: 'hidden',  
                textOverflow: 'ellipsis', 
                flexGrow: 1,
                width: '100%',
              }}
              style={{ color: '#FFD700' }} // Inline style to ensure text color is applied
            >
              {/* Detail */}
            </Typography>
            {/* SEARCH */}
            {/* <TextField
              placeholder="Type to search..."
              value={filteredProjectName}
              onChange={handleFilterProjectName}
              variant="outlined"
              size="small"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon style={{ color: '#B2EBF2' }} />
                  </InputAdornment>
                ),
                style: {
                  color: '#B2EBF2', // Text color
                }
              }}
              sx={{
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor: '#B2EBF2', // Border color
                  },
                  '&:hover fieldset': {
                    borderColor: '#B2EBF2', // Border color on hover
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: '#B2EBF2', // Border color when focused
                  }
                },
                '& .MuiInputLabel-root': {
                  color: '#B2EBF2', // Label color
                },
                '& .MuiInputLabel-root.Mui-focused': {
                  color: '#B2EBF2', // Label color when focused
                },
              }}
            /> */}
            {/* <IconButton
             onClick={downloadCSV}
              color="secondary"
              aria-label="add"
              sx={{
                backgroundColor: '#009688', // Cyan button color
                color: '#FFFFFF', // White icon color
                '&:hover': {
                  backgroundColor: '#00796B', // Darker cyan on hover
                },
              }}
            >
              <FileDownloadIcon/>
            </IconButton> */}
            <Button
              variant="contained"
              size="medium"
              onClick={downloadCSV}
              sx={{
                backgroundColor: 'teal',
                width: '150px',
                fontSize: '12px', // Adjust the font size as needed
                '&:hover': {
                  backgroundColor: 'darkslategray' // Optional: change color on hover
                }
              }}
            >
              Download CSV
            </Button>
          </Box>
          
        {/* <Table aria-labelledby="tableTitle"> 
          <TableHead style={{backgroudColor:'black'}}>
            <TableRow>
                <TableCell style={{ width: '30%', fontWeight: 'bold', color: '#FFD700'}}>Name</TableCell>
                <TableCell style={{ width: '15%', textAlign: 'center', fontWeight: 'bold', color: '#B2EBF2' }}>Project Manager</TableCell>
                <TableCell style={{ width: '15%', textAlign: 'center', fontWeight: 'bold', color: '#B2EBF2' }}>Period</TableCell>
                <TableCell style={{ width: '15%', textAlign: 'center', fontWeight: 'bold', color: '#B2EBF2' }}>Status</TableCell>
                <TableCell style={{ width: '5%', textAlign: 'center', fontWeight: 'bold', color: '#B2EBF2' }}>Percent</TableCell>
                <TableCell style={{ width: '15%', textAlign: 'center', fontWeight: 'bold', color: '#B2EBF2' }}>Progress</TableCell>
                <TableCell style={{ width: '5%'}}></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
          {filteredProjects !== undefined ? filteredProjects
              .map((item) => (
                <CustomTableRow>
                    <TableCell style={{ color: '#FFD700', fontSize: '18px' }}>
                      <Link to={`/form/${item.check_on}`} onClick={handleLinkClick(item.team)} style={{ color: '#FFD700'}}>
                        {item.project_name}
                      </Link>
                    </TableCell>
                    <TableCell style={{ textAlign: 'center', color: '#B2EBF2', fontSize: '18px'  }}>{item.project_manager}</TableCell>
                    <TableCell style={{ textAlign: 'center', color: '#B2EBF2' }}>
                      {item.start_date && item.finish_date ? (
                        <>
                          {new Intl.DateTimeFormat('en-US', { month: 'short', year: 'numeric' }).format(new Date(item.start_date.substring(0, 10)))} - 
                          {new Intl.DateTimeFormat('en-US', { month: 'short', year: 'numeric' }).format(new Date(item.finish_date.substring(0, 10)))}
                        </>
                      ) : (
                        '-'
                      )}
                    </TableCell>
                    <TableCell style={{ textAlign: 'center', color: '#B2EBF2', fontSize: '18px'  }}>{item.status}</TableCell>
                    <TableCell style={{ textAlign: 'center', color: '#B2EBF2', fontSize: '18px'  }}>
                      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <div style={{ position: 'relative' }}>
                          <CircularProgress variant="determinate" sx={{ color: '#00796B' }} value={item.percent_progress} />
                          <Typography
                            variant="h6"
                            component="div"
                            style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}
                          >
                            { item.percent_progress !== null ?`${item.percent_progress}%` : '0%'}
                          </Typography>
                        </div>
                      </div>
                    </TableCell>
                    <TableCell style={{ textAlign: 'center', color: '#B2EBF2', fontSize: '18px', wordWrap: 'break-word', maxWidth: '200px' }}>
                      <Tooltip title={item.progress_update} arrow>
                        <Typography
                          variant="h6"
                          component="div"
                          style={{
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            maxWidth: '100%',
                          }}
                        >
                          {item.progress_update}
                        </Typography>
                      </Tooltip>
                    </TableCell>
                    <TableCell style={{ color: '#B2EBF2' }}>
                      <IconButton
                        color="error"
                        aria-label="delete"
                        onClick={() => handleClickOpen(item.check_on)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </TableCell>
                </CustomTableRow>
              )): ''
              }
          </TableBody>
        </Table> */}
        {filteredProjects !== undefined 
        ? 
        // console.log(filteredProjects)
        <FilteringTable 
          filteredProjects={filteredProjects} 
          handleLinkClick={handleLinkClick} 
          handleClickOpen={handleClickOpen}
          handleConfirmDelete={handleConfirmDelete}
        />
        : ''
        }
        </Box>
      </Item>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Do you want to delete the project?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            This action cannot be undone. Are you sure you want to delete this project?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleConfirmDelete} color="error" autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    
    </Box>
  )
}

export default FormSummary
