import { Box, Button, Divider, Grid, Paper, Stack, Typography, useTheme } from "@mui/material"
import { tokens } from "../../theme"
import { styled } from '@mui/material/styles'
import coeLogoWhite from '../../assets/images/coelogo4whitebg.png'
import coeLogoBlack from '../../assets/images/coelogo4blackbg.png'
import { Link, useNavigate } from "react-router-dom";
import { apiUrl } from '../../constants'
import { useState, useEffect} from 'react'
import api from '../../axiosInstance';
import { useSelector, useDispatch } from 'react-redux';
import { setTeam } from '../../reducers/authSlice';


const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1C1B29' : '#1C1B29',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
  height: '100%',
  width: '100%',
  borderRadius:'10px',
}));

const Home = () => {
  const year = new Date().getFullYear();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [projects, setProjects] = useState([])
  const { team, role } = useSelector((state) => state.auth);
  const teams = role !== 'superuser' ? [{ id: 1, label: team }] : [
    { id: 1, label: "ความปลอดภัย อาชีวอนามัย สิ่งแวดล้อมและพลังงาน" },
    { id: 2, label: "Strategy & Engineering Management" },
    { id: 3, label: "พัฒนาประสิทธิภาพและนวัตกรรม" },
    { id: 4, label: "วิศวกรรมพื้นฐานและงานระบบ" },
    { id: 6, label: "วิศวกรรมแปรรูปเนื้อสัตว์" },
    { id: 7, label: "วิศวกรรมอาหารสำเร็จรูป" },
    { id: 8, label: "วิศวกรรมเพื่อความยั่งยืน" },
    { id: 9, label: "วิศวกรรมก่อสร้าง" },
    { id: 10, label:"วิศวกรรมสัตว์บก" },
    { id: 11, label:"วิศวกรรมเกษตร" },
    { id: 11, label:"วิศวกรรมสัตว์น้ำ" },
  ]

  const teamsTech = role !== 'superuser' ? [{ id: 1, label: team }] : [
    { id: 1, label: "Strategy & Engineering Management" },
    { id: 2, label: "พัฒนาประสิทธิภาพและนวัตกรรม" },
    { id: 3, label: "วิศวกรรมพื้นฐานและงานระบบ" },
    { id: 4, label: "วิศวกรรมเพื่อความยั่งยืน" },
    { id: 5, label: "ความปลอดภัย อาชีวอนามัย สิ่งแวดล้อมและพลังงาน" },
    { id: 6, label: "วิศวกรรมก่อสร้าง" },
  ]

  const teamsOper = role !== 'superuser' ? [{ id: 1, label: team }] : [
    { id: 1, label: "วิศวกรรมแปรรูปเนื้อสัตว์" },
    { id: 2, label: "วิศวกรรมอาหารสำเร็จรูป" },
    { id: 3, label:"วิศวกรรมสัตว์น้ำ" },
    { id: 4, label:"วิศวกรรมสัตว์บก" },
    { id: 5, label:"วิศวกรรมเกษตร" },
  ]
  const dispatch = useDispatch();
  
  const handleButtonClick = (button) => {
    dispatch(setTeam(button.label));

  };
  

  // get projects, first loading
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await api.post(apiUrl.ALL_PROJECTS_SUMMARY_URL, {'year': year});
        setProjects(response.data.projects)
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchData();
  }, []);

  // const downloadCSV = () => {
  //   const headers = ['Project Name', 'Subteam', 'Strategic Direction CPF', 'Strategic Direction COE',
  //     'Project Type', 'BU', 'Location', 'Project Manager', 'Members', 'Period', 'Status', 'Percent Progress',
  //     'Progress Update', 'Problem', 'Next Step', 'Week', 'Latest Update', 'Budget', 'Cost Saving Type',
  //     'Actual Saving (MB)', 'Target Saving (MB)'
  //   ];
  //   console.log('projects')
  //   console.log(projects)
  //   const rows = projects.map(project => [
  //     project.project_name,
  //     project.subteam,
  //     project.strategic_direction_cpf,
  //     project.strategic_direction_coe,
  //     project.project_type,
  //     project.bu,
  //     project.location,
  //     project.project_manager,
  //     project.members,
  //     `${project.start_date ? new Intl.DateTimeFormat('en-US', { month: 'short', year: 'numeric' }).format(new Date(project.start_date.substring(0, 10))) : 'N/A'} - ${project.finish_date ? new Intl.DateTimeFormat('en-US', { month: 'short', year: 'numeric' }).format(new Date(project.finish_date.substring(0, 10))) : 'N/A'}`,
  //     project.status,
  //     project.percent_progress,
  //     project.progress_update,
  //     project.problem,
  //     project.next_step,
  //     project.week,
  //     project.latest_created_at,
  //     project.budget,
  //     project.cost_saving_type,
  //     project.total_actual_saving_mb,
  //     project.total_target_saving_mb,
 
  //   ]);
  
  //   // Helper function to escape CSV fields
  //   const escapeCSVField = (field) => {
  //     if (typeof field === 'string') {
  //       if (field.startsWith('-')) {
  //         field = "'" + field;
  //       }
  //       return `"${field.replace(/"/g, '""')}"`;
  //     }
  //     return field;
  //   };
  
  //   let csvContent = headers.map(escapeCSVField).join(",") + "\n"
  //     + rows.map(row => row.map(escapeCSVField).join(",")).join("\n");
  //   const bom = "\uFEFF";
  //   const csvData = new Blob([bom + csvContent], { type: 'text/csv;charset=utf-8;' });
  //   const link = document.createElement("a");
  //   const url = URL.createObjectURL(csvData);
  //   link.setAttribute("href", url);
  //   link.setAttribute("download", "projects.csv");
  //   document.body.appendChild(link);
  //   link.click();
  //   document.body.removeChild(link);
  // };

  const downloadCSV = () => {
    // Derive headers dynamically from the first object in filteredProjects
    const headers = projects.length > 0 ? Object.keys(projects[0]) : [];
    
    // Generate rows dynamically based on the available keys in filteredProjects
    const rows = projects.map(project => 
      headers.map(header => project[header] ?? '') // Use nullish coalescing to handle undefined values
    );
  
    // Helper function to escape CSV fields
    const escapeCSVField = (field) => {
      if (typeof field === 'string') {
        if (field.startsWith('-')) {
          field = "'" + field;
        }
        return `"${field.replace(/"/g, '""')}"`;
      }
      return field;
    };
  
    // Build CSV content
    let csvContent = headers.map(escapeCSVField).join(",") + "\n"
      + rows.map(row => row.map(escapeCSVField).join(",")).join("\n");
    
    const bom = "\uFEFF"; // Add BOM for proper UTF-8 encoding
    const csvData = new Blob([bom + csvContent], { type: 'text/csv;charset=utf-8;' });
    
    // Create a download link and trigger download
    const link = document.createElement("a");
    const url = URL.createObjectURL(csvData);
    link.setAttribute("href", url);
    link.setAttribute("download", "projects.csv");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  

  return (
    <Box  sx={{ padding: '2px', backgroundColor: 'transparent',}}>
        <Grid container spacing={2}>
      {/* First Column (2x width) */}
      <Grid item xs={12} sm={6} md={6} lg={6} >
      <Box
        sx={{
          minHeight: '90vh',
          bgcolor: 'transparent',
          color: 'white',
          textAlign: 'center',
          p: 2,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <img src={coeLogoBlack} width='300' height='300' alt="COE Logo" />
      </Box>
      </Grid>

      {/* Second Column */}
      <Grid item xs={12} sm={6} md={6} lg={6}>
        <Box
          sx={{
            minHeight: '90vh',
            bgcolor: '#f5f5f5',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            backgroundColor: 'rgba(255, 255, 255, 0.2)',
            p: 2,
          }}
        >
          <Typography variant="h4" gutterBottom color='#FFD700'>
            Project Management System
          </Typography>
          <Typography variant="h4" gutterBottom align="center">
            ...
          </Typography>
          {
            localStorage.getItem("rb_user_role_pms") === 'superuser'?
            <Box
              sx={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  justifyContent: 'center',
                  gap: '8px', // Space between buttons
                  width: '100%', // Full width
              }}
            >
              <Typography 
              variant="h4"
              component="div" 
              color='#FFD700'
              sx={{ 
                  display: 'block',
                  whiteSpace: 'nowrap',
                  textAlign: 'center',
                  overflow: 'hidden',  
                  textOverflow: 'ellipsis', 
                  width: '100%' }}
                  >
                Operational Excellence
              </Typography>
              {teamsOper.map((button) => (
                <Link to='/project-summary' >
                  <Button
                    key={button.id}
                    variant="contained"
                    sx={{
                      minWidth: '100px',
                      bgcolor: '#0f0c29',
                      borderRadius: '20px',
                      fontSize: '18px', // Set font size to 18px
                      '&:hover': {
                        bgcolor: '#4a3f35', // Adjust this to your specific color if `colors.accent[800]` is not defined
                      },
                    }}
                    onClick={() => handleButtonClick(button)}
                  >
                    {button.label}
                  </Button>
                </Link>
              ))}
              <Typography 
              variant="h4"
              component="div" 
              color='#FFD700'
              sx={{ 
                  display: 'block',
                  whiteSpace: 'nowrap',
                  textAlign: 'center',
                  overflow: 'hidden',  
                  textOverflow: 'ellipsis', 
                  width: '100%' }}
                  >
                Technology Excellence
              </Typography>
              {teamsTech.map((button) => (
                <Link to='/project-summary' >
                  <Button
                    key={button.id}
                    variant="contained"
                    sx={{
                      minWidth: '100px',
                      bgcolor: '#0f0c29',
                      borderRadius: '20px',
                      fontSize: '18px', // Set font size to 18px
                      '&:hover': {
                        bgcolor: '#4a3f35', // Adjust this to your specific color if `colors.accent[800]` is not defined
                      },
                    }}
                    onClick={() => handleButtonClick(button)}
                  >
                    {button.label}
                  </Button>
                </Link>
              ))}
            </Box> 
            :
            <Box
              sx={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  justifyContent: 'center',
                  gap: '8px', // Space between buttons
                  width: '100%', // Full width
              }}
            >
              {teams.map((button) => (
                <Link to='/project-summary' >
                  <Button
                    key={button.id}
                    variant="contained"
                    sx={{
                      minWidth: '300px',
                      bgcolor: '#0f0c29',
                      borderRadius: '20px',
                      fontSize: '18px', // Set font size to 18px
                      '&:hover': {
                        bgcolor: '#4a3f35', // Adjust this to your specific color if `colors.accent[800]` is not defined
                      },
                    }}
                    onClick={() => handleButtonClick(button)}
                  >
                    {button.label}
                  </Button>
                </Link>
              ))}
            </Box> 
          }
          <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end', // Aligns the button to the right
            width: '100%', // Makes sure the container takes full width
          }}
        >
          <Button
            variant="contained"
            size="medium"
            onClick={downloadCSV}
            sx={{
              backgroundColor: 'teal',
              width: '150px',
              fontSize: '12px', // Adjust the font size as needed
              '&:hover': {
                backgroundColor: 'darkslategray', // Optional: change color on hover
              },
            }}
          >
            Download CSV
          </Button>
        </Box>
        </Box>
      </Grid>
    </Grid> 
    </Box>
  );
};

export default Home;