import {useState} from 'react';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { CircularProgress, Typography, IconButton, Tooltip } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { Link } from 'react-router-dom';

const FilteringTable = (props) => {
    const [open, setOpen] = useState(false);
    const [currentItem, setCurrentItem] = useState(null);
   
    const filteredProjectsWithIds = (props.filteredProjects || []).map((project, index) => ({
        id: project.check_on || index, // Use a unique identifier from your project data, or fallback to index
        ...project,
    }));
    // console.log(filteredProjectsWithIds)
    const columns = [
        {
          field: 'project_name',
          headerName: 'Project Name',
          renderCell: (params) => (
            <Tooltip title={params.value} arrow>
               <div
                style={{
                whiteSpace: 'normal', // Allow text to wrap
                wordBreak: 'break-word', // Break words if necessary
                textAlign: 'left', // Align text to the left
                }}
            >
                <Link 
                  to={`/form/${params.row.check_on}`} 
                  style={{ color: '#FFD700', textDecoration: 'none' }}
                  target="_blank" // Open in a new tab
                  rel="noopener noreferrer" // Security best practice
                >
                  {params.value}
                </Link>
            </div>
            </Tooltip>
          ),
          flex: 3, // 20% width
        },
        {
          field: 'project_manager',
          headerName: 'Manager',
          flex: 1.5,
          align: 'center',
          headerAlign: 'center',
        },
        {
          field: 'year',
          headerName: 'Year',
          flex: 1.5,
          align: 'center',
          headerAlign: 'center',
        },
        {
          field: 'date_range',
          headerName: 'Period',
          valueGetter: (params) => {
            if (params.row.start_date && params.row.finish_date) {
              const startDate = new Intl.DateTimeFormat('en-US', { month: '2-digit', year: '2-digit' }).format(new Date(params.row.start_date.substring(0, 10)));
              const finishDate = new Intl.DateTimeFormat('en-US', { month: '2-digit', year: '2-digit' }).format(new Date(params.row.finish_date.substring(0, 10)));
              return `${startDate} - ${finishDate}`;
            }
            return '-';
          },
          flex: 2,
          align: 'center',
          headerAlign: 'center',
        },
        {
          field: 'status',
          headerName: 'Status',
          flex: 1.5,
          align: 'center',
          headerAlign: 'center',
        },
        {
          field: 'percent_progress',
          headerName: 'Progress',
          renderCell: (params) => (
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <div style={{ position: 'relative' }}>
                <Typography
                  variant="h6"
                  component="div"
                  style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}
                >
                  {params.value !== null ? `${params.value}%` : '0%'}
                </Typography>
              </div>
            </div>
          ),
          flex: 1,
          align: 'center',
          headerAlign: 'center',
        },
        {
          field: 'progress_update',
          headerName: 'Progress Update',
          renderCell: (params) => (
            <Tooltip title={params.value} arrow>
              <Typography
                variant="h6"
                component="div"
                style={{
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  maxWidth: '100%',
                }}
              >
                {params.value}
              </Typography>
            </Tooltip>
          ),
          flex: 2,
          align: 'center',
          headerAlign: 'center',
        },
        {
          field: 'created_at',
          headerName: 'Latest Update',
          renderCell: (params) => (
            <Typography
              variant="h6"
              component="div"
              style={{
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                maxWidth: '100%',
              }}
            >
              { params.value?
                new Intl.DateTimeFormat('en-US', { day: 'numeric', month: '2-digit', year: '2-digit' }).format(new Date(params.value.substring(0, 10)))
                : ''
              }
            </Typography>
          ),
          flex: 1.5, // 10% width
          align: 'center',
          headerAlign: 'center',
        },
        {
          field: 'actions',
          headerName: 'Actions',
          renderCell: (params) => (
            <IconButton 
            color="error" 
            aria-label="delete"
            onClick={() => props.handleClickOpen(params.row.check_on)} // Attach onClick handler here
            >
                <DeleteIcon />
            </IconButton>
          ),
          flex: 1, // 10% width
          align: 'center',
          headerAlign: 'center',
        },
    ];
    
    const handleClickOpen = (item) => {
        setCurrentItem(item); // Set the item to be deleted
        setOpen(true); // Open the dialog
    };

    const handleClose = () => {
        setOpen(false); // Close the dialog without deleting
    };

      
      
  return (
    <div style={{ height: 600, width: '100%' }}>
      <DataGrid
        rows={filteredProjectsWithIds}
        columns={columns}
        pageSize={10}
        rowsPerPageOptions={[10]}
        density="comfortable"
        components={{
          Toolbar: GridToolbar,
        }}
        componentsProps={{
            toolbar: {
              csvOptions: { disableToolbarButton: true },
              printOptions: { disableToolbarButton: true },
              showQuickFilter: true,
              quickFilterProps: { debounceMs: 250 },
            },
          }}
        sx={{
            '& .MuiDataGrid-columnHeaders': {
                backgroundColor: '#B2EBF2',
                fontSize: '18px',
                color: '#333',
            },
            '& .MuiDataGrid-cell': {
                color: '#B2EBF2',
                fontSize: '18px',
            },
            '& .MuiDataGrid-toolbarContainer': {
                '& .MuiButtonBase-root': {
                color: '#FFD700', // Change the color of buttons in the toolbar
                },
                '& .MuiTypography-root': {
                color: '#FFD700', // Change the color of any text in the toolbar
                },
            }
        }}
      />
    </div>
  )
}

export default FilteringTable